import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/Layout"

const JobsPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Jobs - Outdated</title>
        <meta name="description" content="This section is outdated" />
      </Helmet>
      <h1>This section is outdated</h1>
    </Layout>
  )
}

export default JobsPage
